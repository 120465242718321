$(document).on("click", "body.allowed_id_numbers .summary .button", () => {
  const $select_file = $("#select_file");
  $select_file.val("");
  $select_file.click();
});

$(document).on("change", "body.allowed_id_numbers #select_file_form", () => {
  const $select_file_form = $("#select_file_form");
  const formData = new FormData($select_file_form[0]);
  const files = formData.getAll("file");

  // ファイル未選択時
  if (files.length === 0 || files[0].name.length === 0) return;

  // 確認ダイアログ表示
  const filename = formData.get("file").name;
  if (!filename.match(/\.csv$/i)) {
    alert("CSV ファイルを選択してください。");
    return;
  }

  const rslt = confirm(`ファイルを読み込みます。\n${filename}`);
  if (!rslt) return;

  $select_file_form.submit();
});
