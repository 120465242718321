// メニュー表示
$(document).on("click", ".page-header .header-menu .img-button", e => {
  $(".header-menu .menu").toggle();

  // メニューと関係ないところがクリックされたらメニューを閉じる
  $(document).one("click", e => {
    $(".header-menu .menu").hide();
  });

  return false;
});
